
import {
  defineComponent,
  ref,
  PropType,
  EmitsOptions,
  onMounted,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { Address } from "@/models/DashboardModel";
import { useStore } from "vuex";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";

export default defineComponent({
  name: "new-address-template-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {},
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    const functions = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    const dataTypes = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);

    const store = useStore();
    //const isWriteSingleCoil = ref(false);
    const slaveTemplateId = computed(() => store.getters.curSlaveTemplateId);
    const addressTemplateId = computed(
      () => store.getters.curAddressTemplateId
    );

    //Omdat aan de select geen object maar alleen een id is gekoppeld en ID alles kan zijn terwijl ik wil checken op  function.int == 5
    const isWriteSingleCoil = computed(() => {
      var result = false;
      if (functions.value.length == 0) {
        return false;
      } else {
        for (let i = 0; i < functions.value.length; i++) {
          if (
            functions.value[i].id == newAddressTemplateData.value.functionId
          ) {
            result = functions.value[i].int == 5;
          }
        }
        return result;
      }
    });

    var newAddressTemplateData = ref<Mast.AddressTemplate>({
      id: 0,
      slaveTemplateId: slaveTemplateId.value,
      name: "",
      unit: "",
      busAddress: 0,
      function: {
        id: 0,
        genericOptionTypeId: 0,
        int: 0,
        float: 0,
        string: "",
        selected: false,
      },
      functionId: 0,
      dataType: {
        id: 0,
        genericOptionTypeId: 0,
        int: 0,
        float: 0,
        string: "",
        selected: false,
      },
      dataTypeId: 0,
      decimals: 0,
    });

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      unit: Yup.string().required().label("Unit"),
      busAddress: Yup.number().required().label("Bus-Address"),
      function: Yup.number().required().min(1).label("Function"),
      dataTypeId: Yup.number().label("Type"),
      decimals: Yup.number().label("Decimals"),
      // name: Yup.string().required().label("Name"),
      // unit: Yup.string().required().label("Unit"),
      // busAddress: Yup.number().required().label("Bus-Address"),
      // function: Yup.number().required().label("Function"),
      // dataTypeId: Yup.number().required().label("Type"),
      // decimals: Yup.number().required().label("Decimals"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button and busy indicator
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      if (newAddressTemplateData.value.dataTypeId == 0)
        newAddressTemplateData.value.dataTypeId = dataTypes.value[0].id;//nodig als singleWritecoil is geselcteerd anders fout uit backend

      ApiService.post(
        "/template/addresstemplateupdate",
        newAddressTemplateData.value as any
      )
        .then(({ data }) => {
          console.log("submit AddressTemplate response data:"); //todo geert
          console.log(data); //todo geert
          //console.log('push '+data.slaveTemplateId)
          //store.commit('setSlaveTemplateId',data.slaveTemplateId) hoeft niet staat nog goed
          router.push({ name: "editslavetemplate" });
        })
        .catch(({ response }) => {
          console.log("Fout AddressTemplateUpdate");
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const getAddressTemplateById = (id) => {
      console.log("getAddressTemplateById id:");
      console.log(id);

      ApiService.get2("/template/addressTemplateget?id=" + id)
        .then(({ data }) => {
          console.log("getaddress response data:"); //todo geert
          console.log({ ...data }); //todo geert
          newAddressTemplateData.value = data as Mast.AddressTemplate;
        })
        .catch(({ response }) => {
          console.log("Fout getAddressTemplateById");
        });
    };

    const clickCancel = () => {
      router.go(-1);
    };

    onMounted(() => {
      BnPHelpers.getOptions("/template/datatypes", dataTypes)
        .then(() => {
          BnPHelpers.getOptions("/template/functions", functions);
        })
        .then(() => {
          if (addressTemplateId.value != 0) {
            getAddressTemplateById(addressTemplateId.value);
            console.log("NewAddressTemplateData is nu:");
            console.log(newAddressTemplateData.value.name);
          }
        });
    });

    return {
      newAddressTemplateData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      clickCancel,
      dataTypes,
      functions,
      isWriteSingleCoil,
    };
  },
});
