import ApiService from "@/core/services/ApiService";
import * as Mast from "@/models/MasterModel"

var units = {
  year  : 24 * 60 * 60 * 1000 * 365,
  month : 24 * 60 * 60 * 1000 * 365/12,
  day   : 24 * 60 * 60 * 1000,
  hour  : 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
}


class BnPHelpers {

  public static getRelativeTime = (d2 = new Date()) => {
    var d1 = new Date()
    var elapsed = d1.getTime() - d2.getTime()
    //console.log(elapsed);
    if(elapsed <10000) return "A few seconds ago"
    
    var rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

    // "Math.abs" accounts for both "past" & "future" scenarios
    for (var u in units) 
      if (Math.abs(elapsed) > units[u] || u == 'second') 
        return rtf.format(Math.round(-elapsed/units[u]), u as Intl.RelativeTimeFormatUnit )
  }

public static getOptions = (url:string,list) => {

  
    console.log("get"+url); 
     return ApiService.get2(url)
     .then(({ data }) => {
         //console.log('get'+name+' response data:');//todo geert
         //console.log({...data});//todo geert
         list.value = data as Mast.GenericOption[] ;

     })
     .catch(({ response }) => {
       console.log("Fout get"+url)
     });
 };

 


}


export default BnPHelpers;
