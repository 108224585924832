
    import { defineComponent, onMounted} from "vue";
    //import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
    import addressTemplateEditform from "@/components/AddressTemplateEditForm.vue";
    import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
    //import { MenuComponent } from "@/assets/ts/components";
    //import masterstable from "@/components/MastersTable.vue"

    export default defineComponent({
        name: "address-template-edit-page",
        components: {
            addressTemplateEditform,

        },
        setup() {
            onMounted(() => {
                //setCurrentPageBreadcrumbs("Charts", ["Widgets"]);
                setCurrentPageTitle("Edit address-template");
                //MenuComponent.reinitialization();
            });

                    
            return {
                addressTemplateEditform
            };
        },


    });
